
//Models 
import HomeModel from '../Home_view/model.js';
import MapViewModel from '../Map_view/model.js';
import ListViewModel from '../List_view/model.js';
import AppModel from '../App/model.js';
import FiltersModel from './model.js';

// update the query string based on the selected filters
const updateQueryString = FiltersModel.updateQueryString;

// to mirror the value from input to html so we can style it base on it's value
const mirrorValueToHtml = FiltersModel.mirrorValueToHtml;

//update the home page map | listing view | map view based on the selected filters
const updateHomePageMap = HomeModel.updateHomePageMap;
const getMapViewAds = MapViewModel.getAds;
const getListingAds = ListViewModel.getAds;

// show alert message
const showAlert = AppModel.showAlert;
const correctPopupPosition = AppModel.correctPopupPosition;

// set the state of first selected real estate type filter
const setStateRealEstateTypeFilter = FiltersModel.setStateRealEstateTypeFilter;
const resetStateRealEstateTypeFilter = FiltersModel.resetStateRealEstateTypeFilter;

let lastCheckedListedBy  = null;

$(document).ready(function(){
    const $mapbox = $('#search-map');
    //init state of form query string
    $('#search-page-filters').data('query', updateQueryString($('#search-page-filters')));

    /*
        * getting the suggestions of places from meilisearch
    */
    let locationSuggestionsTimer = null;
    const LocationSuggestionsUrl = $('#location').data('route');
    const $suggestionsContainer = $('#suggestions');
    $(document).on('keyup', '#location', function (e) {

        if (locationSuggestionsTimer) clearTimeout(locationSuggestionsTimer);
        if (e.target.value == '') {
            $suggestionsContainer.html('');
            $suggestionsContainer.hide();
            return;
        };

        // to wait until the user finish typing
        locationSuggestionsTimer = setTimeout(function () {
            $.ajax({
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                url: LocationSuggestionsUrl,
                type: 'GET',
                data: { q: e.target.value },
                success: function (response) {
                    if (response.html === null) {
                        $suggestionsContainer.html('');
                        $suggestionsContainer.hide();
                    } else {
                        $suggestionsContainer.html('');
                        $suggestionsContainer.append(response.html);
                        $suggestionsContainer.show();
                    }
                },
                error: function (_, status, __) {
                    console.log(status);
                }
            })
        }, 1250);
    })

    $(document).on('click', '.suggestion', function () {
        const $form = $('#search-page-filters');
        const $locationInput = $('#location');
        const $locationsInputContainer = $(".locations-container");
        const $selectedLocationsContainer = $("#selected-locations"); // selected locations container
        const $selectedLocations = $('.location-bubble'); // selected locations bubbles
        const location = $(this).find('.location-name').text().trim(); // selected location text
        var locationsCount = $selectedLocations.length; // Count of hidden bubbles

        const isLocationSelected = $(`input[value="${location.toLowerCase()}"]`);
        if (isLocationSelected.length > 0) {
            return;
        }
        //inserting new location bubble element
        if ($selectedLocations.length < 5) {
            if (location) {
                $selectedLocationsContainer.append(`<div class="location-bubble bg-[#EDF4FF] px-4 py-1 rounded-full hover:cursor-pointer flex gap-4">
                                                        ${location}
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                            stroke-width="1.5" stroke="currentColor" class="w-5 h-6">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>`);
                $selectedLocationsContainer.append(`<input type="hidden" name="locations[]" value="${location.toLowerCase()}">`);
                $locationInput.val(''); // Clear the input field
            }
            if ($('.location-bubble').length === 5) {
                $locationInput.hide();
                $locationInput.val('');
                $locationInput.prop('disabled', true);
            }
        }

        // Hide the location bubbles element if they exceed the container width or the count of hidden locations bubbles is not equal to zero
         const $counterBubble = $(`<div class="counter-bubble bg-[#EDF4FF] px-4 py-1 rounded-full hover:cursor-pointer gap-4 hidden"></div>`);

        if (locationsCount >= 1) {
            // Hide all bubbles except the first
            $('.location-bubble').not(':first').removeClass('flex').addClass('hidden');
            var hiddenCount = $('.location-bubble').length - 1; // Subtract one for the visible bubble

            // Show the counter bubble with the count of hidden bubbles
            if (hiddenCount > 0) {
                $('.counter-bubble').remove();
                $counterBubble.text('+' + hiddenCount + ' more').removeClass('hidden');
                $selectedLocationsContainer.append($counterBubble);
            } else {
                $counterBubble.addClass('hidden');
            }
        }

        $locationsInputContainer.removeClass('border-2 border-blue-brand focus').addClass('border');
        $locationInput.blur();
        $suggestionsContainer.html('');
        $suggestionsContainer.hide();

        //trigger submit to update the results on listing page
        if ($form.hasClass('listing-view')) {
            getListingAds();
        }else if($form.hasClass('map-view')){
            MapViewModel.realEstateAdIds = null;
            MapViewModel.boundaries = null;
            getMapViewAds();
        }

    });

    // Show the location bubbles if the user clicks on the location input container
    $(document).on('click', '.locations-container', function (e) {
        e.stopPropagation();
        const $container = $(this);

        $container.removeClass('border').addClass('border-2 border-blue-brand focus');
        $('.counter-bubble').addClass('hidden');
        $('.location-bubble').removeClass('hidden').addClass('flex');

    });

    // remove the selected location from location input container
    $('body').on('click', '.location-bubble', function (event) {
        event.stopPropagation();

        const $form = $('#search-page-filters');
        const $locationBubble = $(this);
        const $input = $('#location');
        const $counterBubble = $('.counter-bubble');
        const $locationInputContainer = $('.locations-container');
        const inputValue = $locationBubble.text().trim().toLowerCase();

        $form.find(`input[value="${inputValue}"]`).remove();
        $locationBubble.remove();

        const $selectedLocations = $('.location-bubble');
        var hiddenCount = $selectedLocations.length - 1;

        if (!$locationInputContainer.hasClass('focus')) {
            $locationInputContainer.removeClass('border').addClass('border-2 border-blue-brand focus');
            $counterBubble.addClass('hidden');
            $selectedLocations.removeClass('hidden').addClass('flex');
        }
        if (hiddenCount > 0) {
            $counterBubble.text('+' + hiddenCount + ' more');
        } else {
            $counterBubble.remove();
        }

        // enable keywords input if the selected keywords are less than 5
        if ($selectedLocations.length < 5) {
            $input.val('');
            $input.show();
            $input.prop('disabled', false);
        }

        //trigger submit to update the results on listing page
        if ($form.hasClass('listing-view')) {
            getListingAds();
        }else if($form.hasClass('map-view')){
            MapViewModel.realEstateAdIds = null;
            MapViewModel.boundaries = null;
            getMapViewAds();
        }
    })


    $('body').on('click', function (e) {
        const $target = $(e.target);

        // Hide the location bubbles if the user clicks outside of it
        if (!$target.is('.locations-container *')) {
            $('.locations-container').removeClass('border-2 border-blue-brand focus').addClass('border');
            $('.location-bubble').not(':first').removeClass('flex').addClass('hidden');
            $('.counter-bubble').removeClass('hidden');
            $('#location').val('').blur();
        } 
        
        // Hide the alert modal
        if($target.is('#create-alert-modal')){
            $target.removeClass('grid place-items-center')
            .addClass('hidden');
        }

        // Hide the suggestions container if the user clicks outside of it
        $suggestionsContainer.html('');
        $suggestionsContainer.hide();
        
        // Check if there is any open popup and close all open popups when click outside
        const $openPopup = $('.search-popup-wraper.opened');

        if ($openPopup.length) {
            // Check if the click is outside the open popup
            if (!$openPopup.is($target) && $openPopup.has($target).length === 0) {
                //remove the overlay from the mapbox when the popup is closed
                if($mapbox.length > 0 && $mapbox.find('#mapbox-overlay').length > 0){
                    $mapbox.find('#mapbox-overlay').remove();
                }
                // Close all open popups and trigger filter if not empty
                $.each($openPopup, function (_, popup) {
                    const $popup = $(popup);
                    let $popupId = $popup.attr('data-dropdown-toggle');
                    $popup.find('i').css('transform', 'rotate(0deg)');
                    $popup.removeClass('opened');
                    if($popupId !== "ad-types-dropdown" || $popupId !== "sort-option"){
                        $popup.find('button[aria-label=Done]').trigger('click');
                    }
                });        
            }
        }
    });

    // Types menu, pieces menu, surface menu and other filtration toggle visibility
    $('[data-dropdown-toggle] > span').on('click', function () {    
        const $target = $(this);
        const $filterDropdownButton = $target.parent();
        const targetId = $filterDropdownButton.data('dropdown-toggle');
        
        if ($filterDropdownButton.hasClass('opened')) {
            const $doneBtn = $filterDropdownButton.find('button[aria-label=Done]');
            if($doneBtn.length){
                $doneBtn.trigger('click');
            }else{
                $filterDropdownButton.removeClass('opened');
                $target.find('i').css('transform', 'rotate(0deg)');
            }
            $('#' + targetId).parent().trigger('focus');

            //remove the overlay from the mapbox when the popup is closed
            if($mapbox.length > 0 && $mapbox.find('#mapbox-overlay').length > 0){
                $mapbox.find('#mapbox-overlay').remove();
            }

        } else {
            // add overlay to the mapbox when the popup is opened to prevent the user from interacting with the map
            if($mapbox.length > 0 && $mapbox.find('#mapbox-overlay').length === 0){
                $mapbox.append(`
                    <div id="mapbox-overlay"></div>
                `);
            }
            const $openPopup = $('.search-popup-wraper.opened');

            // to close all other open popup only when we are trying to open new one
            $openPopup.removeClass('opened');
            $openPopup.find('button[aria-label=Done]').trigger('click');
            $openPopup.find('i').css('transform', 'rotate(0deg)');
            
            $filterDropdownButton.addClass('opened');
            $target.find('i').css('transform', 'rotate(180deg)');

            correctPopupPosition(targetId);
        }
    });

    // adding keywords to search filters
    $('#keyword-input').on('keypress', function (event) {
        if (event.which == 13) { // 13 is the Enter key
            event.preventDefault();
            const keywords = $('.keyword'); // actual selected keywords
            const input = $(this);

            keywords.each(function (_, keyword) {
                if($(keyword).text().trim().toLowerCase() == input.val().trim().toLowerCase()){
                    input.val('');
                    return;
                }
            });
            
            if (keywords.length < 5) {
                const keyword = input.val().trim();
                if (keyword) {
                    const selectedKeywords = $("#selected-keywords"); // selected keywords container
                    const keywordBubble = `
                        <div class="keyword text-sm flex items-center gap-2 bg-[#EDF4FF] px-4 py-1 rounded-full hover:cursor-pointer">
                            ${keyword}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                        `;
                    selectedKeywords.append(keywordBubble.trim());
                    selectedKeywords.append(`<input type="hidden" name="keywords[]" value="${keyword.toLowerCase()}">`);
                    input.val(''); // Clear the input field
                }
                if ($('.keyword').length === 5) {
                    input.attr('placeholder', 'Reached the Max Keywords');
                    input.val('');
                    input.prop('disabled', true);
                }
            }
        }
    });

    // remove the selected keyword from search filter
    $('body').on('click', '.keyword', function (event) {
        event.stopPropagation();

        const input = $('#keyword-input');
        const keyword = $(this);
        const inputValue = keyword.text().slice(0, -1).toLowerCase();

        $(`input[value="${inputValue}"]`).remove();
        keyword.remove();

        let keywords = $('.keyword');

        // enable keywords input if the selected keywords are less than 5
        if (keywords.length < 5) {
            input.val('');
            input.attr('placeholder', 'E.g');
            input.prop('disabled', false);
        }

    });

    // this function is meant to update the span after user choose filter (Range buttons)
    $('[data-range]').on('keyup', function () {
        // read all the required values from the fields
        let key = $(this).attr('data-range')
        let from = $(`[data-range="${key}"][data-range-from]`);
        let to = $(`[data-range="${key}"][data-range-to]`);
        let target = $('#' + $(this).attr('data-range-target-id'));
        let defaultText = target.attr('data-range-default');
        let unit = target.attr('data-range-unit');

        // set up default value if the input are empty
        let from_val = from.val().trim() == '' ? '0' : from.val();
        let to_val = to.val().trim() == '' ? 'Tous' : to.val();

        if (from_val == 0 && to_val == 'Tous') {
            // both input are empty, restore the default text
            target.text(defaultText);
        } else {
            // display 1000 as 1k
            let _from_val = from_val;
            if (from_val != "0") {
                _from_val = parseInt(from_val);
                if (_from_val >= 1000 && _from_val < 1000000) {
                    _from_val = (_from_val / 1000).toFixed(1).replace(/\.0$/, "") + "K";
                } else if (_from_val >= 1000000) {
                    _from_val = (_from_val / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
                }

                _from_val = _from_val.toString();
            }
            let _to_val = to_val ;
            if (to_val != "Tous") {
                _to_val = parseInt(to_val);
                if (_to_val >= 1000 && _to_val < 1000000 ) {
                    _to_val = (_to_val / 1000).toFixed(1).replace(/\.0$/, "") + "K";
                } else if (_to_val >= 1000000) {
                    _to_val = (_to_val / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
                }

                _to_val = _to_val.toString();
            }

            // validate the from/to value
            if (from_val * 1 < to_val * 1 || from_val == 0 || to_val == 'Tous') {
                $(this).parent().removeClass('border-2 border-red-500')
            } else {
                $(this).parent().addClass('border-2 border-red-500')
            }

            target.text(_from_val + " - " + _to_val + " (" + unit + ")");
        }

        correctPopupPosition($(this).closest('.search-popup').attr('id'));
    });

    // update the results on listing page based on the individual filter
    $('button[aria-label=Done]').on('click', function () {
        const $form = $('#search-page-filters');
        const actionBtnsId = $(this).parent().attr('id');
        const currentQuery = $form.data('query');
        const newQuery = updateQueryString($form);

        let filterType = actionBtnsId.substring(0, actionBtnsId.indexOf('-'));
        let $filterDropdown = null;
        let $filterDropdownButton = null;

        if (actionBtnsId === 'more-filter-action-btns') {
            filterType = 'more-filter';
            $filterDropdown = $(`#${filterType}-dropdown`);
            $('#keyword-input').val('');
        } else {
            $filterDropdown = $(`#${filterType}-dropdown`);
        }

        $filterDropdownButton = $filterDropdown.parent();

        if($filterDropdown.attr('id') === 'budget-dropdown'){
            // validate the price and space filters
            const $maxPrice = $('#price_max');
            if (parseInt($maxPrice.val()) < parseInt($('#price_min').val())) {
                showAlert($maxPrice.data('error-msg'), 'error');
                $filterDropdown.find('button[aria-label=Reset]').trigger('click');
                $('#price_min, #price_max').parent().removeClass('border-red-500');
                $filterDropdownButton.removeClass('opened');
                return;
            }
        }

        if($filterDropdown.attr('id') === 'area-dropdown'){
            const $maxSpace = $('#space_max');
            if (parseInt($maxSpace.val()) < parseInt($('#space_min').val())) {
                showAlert($maxSpace.data('error-msg') , 'error');
                $filterDropdown.find('button[aria-label=Reset]').trigger('click');
                $('#space_min, #space_max').parent().removeClass('border-red-500');
                $filterDropdownButton.removeClass('opened');
                return;
            }
        }

        $filterDropdownButton.removeClass('opened');

        //rotate back filter dropdown icon to its original position
        $filterDropdownButton
        .find('i')
        .css('transform', 'rotate(0deg)');

        //remove the overlay from the mapbox when the popup is closed
        if($mapbox.length > 0 && $mapbox.find('#mapbox-overlay').length > 0){
            $mapbox.find('#mapbox-overlay').remove();
        }

        if(newQuery !== currentQuery){
            // check if the filter is applied and reset the filter if it's empty
            if($filterDropdown.data('applied') == true){
                if($filterDropdown.attr('id') === 'pieces-dropdown'){
                    const selectedRooms = $('input[name="pieces_number[]"]:checked').length;
    
                    if(selectedRooms === 0){
                        $filterDropdown.data('applied', false);
    
                        //reset style filter span and text
                        $filterDropdownButton
                        .find('> span')
                        .removeClass('bg-light-blue text-blue-brand')
                        .addClass('bg-slate-100');
                    }
                }
                if($filterDropdown.attr('id') === 'types-dropdown'){
                    const selectedTypes = $('input[name="realestate_type[]"]:checked').length;
                    
                    if(selectedTypes === 0){
                        $filterDropdown.data('applied', false);
    
                        //reset style filter span and text
                        $filterDropdownButton
                        .find('> span')
                        .removeClass('bg-light-blue text-blue-brand')
                        .addClass('bg-slate-100');
                    }
                }
                if($filterDropdown.attr('id') === 'budget-dropdown'){
                    const $maxPrice = $('#price_max');
                    const $minPrice = $('#price_min');
                    const zeroOrEmptyRegex = /^(0+)?$/;
    
                    if((zeroOrEmptyRegex.test($maxPrice.val())) && 
                        (zeroOrEmptyRegex.test($minPrice.val()))){
                        $filterDropdown.data('applied', false);
    
                        //reset style filter span and text
                        $filterDropdownButton
                        .find('> span')
                        .removeClass('bg-light-blue text-blue-brand')
                        .addClass('bg-slate-100');

                        $maxPrice.val('');
                        $minPrice.val('');
                    }
                }
                if($filterDropdown.attr('id') === 'area-dropdown'){
                    const $maxSpace = $('#space_max');
                    const $minSpace = $('#space_min');
                    const zeroOrEmptyRegex = /^(0+)?$/;
    
                    if((zeroOrEmptyRegex.test($maxSpace.val())) && 
                        (zeroOrEmptyRegex.test($minSpace.val()))){
                        $filterDropdown.data('applied', false);
    
                        //reset style filter span and text
                        $filterDropdownButton
                        .find('> span')
                        .removeClass('bg-light-blue text-blue-brand')
                        .addClass('bg-slate-100');

                        $maxSpace.val('');
                        $minSpace.val('');
                    }
                }
                if($filterDropdown.attr('id') === 'more-filter-dropdown'){
                    const checkedInputs = $filterDropdown.find('input:checked').length;
                    const keywordsInput = $('input[name="keywords[]"]').length;

                    if(checkedInputs === 0 && keywordsInput === 0){
                        $filterDropdown.data('applied', false);
    
                        //style filter span and text when selected
                        $filterDropdownButton
                        .find('> span')
                        .removeClass('bg-light-blue text-blue-brand')
                        .addClass('bg-slate-100');
                    }
                }
            }else{
                //trigger submit to update the results on listing page
                $filterDropdown.data('applied', true);
    
                //style filter span and text when selected
                $filterDropdownButton
                .find('> span')
                .removeClass('bg-slate-100')
                .addClass('bg-light-blue text-blue-brand');
            }

            // toggle the [applied] state of the filters in more filters dropdown
            if($filterDropdown.attr('id') === 'more-filter-dropdown'){
                let appliedFiltersCount = 0;
                const $appliedFiltersCount = $('#applied-filters-count');

                $('.filter-option').each(function(_, filterOption){
                    const $filterOption = $(filterOption);
                    
                    const $checkedInputs = $filterOption.find('input:checked');

                    if($checkedInputs.length > 0){
                        $filterOption.data('applied', true);
                        appliedFiltersCount++;
                    }else{
                        $filterOption.data('applied', false);
                    }
                });

                const $keywordsInputs = $('input[name="keywords[]"]');
                const $keywordsFilter = $('#keywords-filter');
                if($keywordsInputs.length === 0){
                    $keywordsFilter.data('applied', false);
                }else{
                    $keywordsFilter.data('applied', true);
                    appliedFiltersCount++;
                }

                if(appliedFiltersCount){
                    $appliedFiltersCount.find('> span').text(appliedFiltersCount);
                    if($appliedFiltersCount.hasClass('hidden')){
                        $appliedFiltersCount.toggleClass('hidden flex justify-center items-center');
                    }
                }else{
                    if($appliedFiltersCount.hasClass('flex')){
                        $appliedFiltersCount.toggleClass('hidden flex justify-center items-center');
                    }
                    $appliedFiltersCount.find('> span').text(0);
                }
            }

            if($filterDropdown.attr('id') === 'types-dropdown'){
                setStateRealEstateTypeFilter($filterDropdown.find('input:checked.first-selected').val());
            }

            //trigger submit to update the results on listing page
            if ($form.hasClass('listing-view')) {
                getListingAds();
            }else if($form.hasClass('map-view')){
                MapViewModel.realEstateAdIds = null;
                MapViewModel.boundaries = null;
                getMapViewAds();
            }else{
                updateHomePageMap();
            }
        }
    });

    //reset individual filter
    $('button[aria-label=Reset]').on('click', function () {
        const $form = $('#search-page-filters');
        const actionBtnsId = $(this).parent().attr('id');
        let filterType = actionBtnsId.substring(0, actionBtnsId.indexOf('-'));
        let $filterDropdown = null;
        let isEmpty = true; //to check if the filter is empty or not and to trigger reset based on that

        //reset more filter dropdown
        if (actionBtnsId === 'more-filter-action-btns') {
            filterType = 'more-filter';
            $filterDropdown = $(`#${filterType}-dropdown`);
            let $inputs = $filterDropdown.find('input');

            $inputs.each(function (_, input) {
                const $input = $(input);
                if ($input.attr('type') === 'checkbox' || $input.attr('type') === 'radio') {
                    if ($input.prop('checked')) isEmpty = false;
                    $input.prop('checked', false);
                } else if ($input.attr('name') === 'keywords[]') {
                    isEmpty = false;
                    $input.remove();
                } else {
                    if ($input.val() !== '') isEmpty = false;
                    $input.val('');
                }
            });
            $('#keyword-input').attr('placeholder', 'E.g').prop('disabled', false);
            $('.keyword').remove();

        } else {
            $filterDropdown = $(`#${filterType}-dropdown`);
            var $input = $filterDropdown.find('input:checked, input[type=number]');

            //reset filter text with default text from arial label
            var $filterText = $(`#${filterType}-input`);
            $filterText.text($filterText.attr('aria-label'));

            if ($input.attr('type') === 'checkbox' || $input.attr('type') === 'radio') {
                if ($input.prop('checked')) isEmpty = false;
                $input.prop('checked', false);

            } else {
                $.each($input, function (_, input) {
                    const $input = $(input);
                    if ($input.val() !== '') isEmpty = false;
                    $input.val('');
                    // ************** MAKE SURE TO UPDATE THIS VALUE ON KEY UP AS WELL **************
                    mirrorValueToHtml(input);
                });
            }

            //reset the bedrooms filter based on the selected rooms
            if($input.attr('name') === 'pieces_number[]'){
                const $bedroomsInput = $('input[name="chambers_number[]"]');
                $bedroomsInput.prop('disabled', false);
                $bedroomsInput.next()
                .removeClass('border-gray-200 text-gray-200 cursor-not-allowed')
                .addClass('text-gray-800 border-gray-400 cursor-pointer');
            }

            //reset the real estate types filter based on the selected types
            if($input.attr('name') === 'realestate_type[]'){
                $input.removeClass('first-selected');
                resetStateRealEstateTypeFilter();
            }
        }

        var isApplied = $filterDropdown.data('applied') ?? false; //to check if the filter is applied or not and to trigger reset based on that

        if(!isEmpty && isApplied){
            $filterDropdown.data('applied', false);

            // reset the [applied] state of the filters in more filters dropdown
            if($filterDropdown.attr('id') === 'more-filter-dropdown'){
                $('.filter-option').data('applied', false);
                const $appliedFiltersCount = $('#applied-filters-count');

                if($appliedFiltersCount.hasClass('flex')){
                    $appliedFiltersCount.toggleClass('hidden flex justify-center items-center');
                }
                $appliedFiltersCount.find('> span').text(0);
            }

            //style filter span and text when resetting
            $filterDropdown.parent()
            .find('> span')
            .removeClass('bg-light-blue text-blue-brand')
            .addClass('bg-slate-100');

            //trigger submit to update the results on listing page
            if ($form.hasClass('listing-view')) {
                getListingAds();
            }else if($form.hasClass('map-view')){
                MapViewModel.realEstateAdIds = null;
                MapViewModel.boundaries = null;
                getMapViewAds();
            }else{
                updateHomePageMap();
            }
        }
    });

    // this function is meant to update the span after user choose filter (Radio button)
    $('[data-selectable]').on('click', function () {
        const $form = $('#search-page-filters');
        const $input = $(this);
        const targetId = $input.attr('data-selectable-target-id');
        const $span = $('#' + targetId); // input text

        //fix the position of the popup 
        let target_elemenet_id = $(this).closest('[data-dropdown-toggle]').data('dropdown-toggle');

        // to give the browser the time to re arrage item if needed as the title of the selection change based on what the user selected
        setTimeout(() => {
            correctPopupPosition(target_elemenet_id);
        }, 100);

        // update the span text based on the selected filter
        if($input.attr('name') === 'pieces_number[]'){
            let selectedRooms = [];
            const $roomsInput = $('input[name="pieces_number[]"]:checked');
            const $bedroomsInput  = $('input[name="chambers_number[]"]');
            const $bedroomsFilter = $('#bedrooms-filter'); // bedrooms filter container
            const $moreFilterDropdown = $('#more-filter-dropdown');
            const $appliedFiltersCount = $('#applied-filters-count'); // applied filters count container in more filters dropdown
            const lastRoom = $roomsInput.last().val();

            $roomsInput.each(function() {
                const inputValue = $(this).val();
                switch(inputValue){
                    case '1':
                        selectedRooms.push('Studio');
                        break;
                    case '5':
                        selectedRooms.push('5p+');
                        break;
                    default:
                        selectedRooms.push(inputValue + 'p');
                        break;
                }
            });
    
            //update the span text in rooms filter
            selectedRooms = selectedRooms.join(',');
            $span.text(selectedRooms);

            //reset the span text if the selected rooms are empty
            if($roomsInput.length === 0){
                $span.text($span.attr('aria-label'));
            }

            //enable the bedrooms filter based of the selected rooms
            $bedroomsInput.prop({
                disabled: false,
                checked: false
            });
            $bedroomsInput.next()
            .removeClass('border-gray-200 text-gray-200 cursor-not-allowed')
            .addClass('text-gray-800 border-gray-400 cursor-pointer');

            if(lastRoom === undefined) return;
            
            $bedroomsInput.each(function(){
                const bedroom = $(this);
                if(bedroom.val() > lastRoom){
                    bedroom.prop('disabled', true);
                    bedroom.next()
                    .removeClass('text-gray-800 border-gray-400 cursor-pointer')
                    .addClass('border-gray-200 text-gray-200 cursor-not-allowed');
                }
            });

            if ($bedroomsFilter.has('input:checked').length === 0) {
                $bedroomsFilter.data('applied', false);
                const count =  parseInt($appliedFiltersCount.find('> span').text());
                if(count > 0){
                    $appliedFiltersCount.find('> span').text(count - 1);
                }
            }

            //reset the state of the more filters dropdown if no other filters are selected beside bedrooms
            if($moreFilterDropdown.find('.filter-option:has(input:checked)').length === 0 && 
            $moreFilterDropdown.find('#keywords-filter input[name="keywords[]"]').length === 0){
                // style filter span and text when resetting
                $moreFilterDropdown.parent()
                .find('> span')
                .removeClass('bg-light-blue text-blue-brand')
                .addClass('bg-slate-100');

                $moreFilterDropdown.data('applied', false); // reset the [applied] state of more filters dropdown

                // reset the count of applied filters
                if($appliedFiltersCount.hasClass('flex')){
                    $appliedFiltersCount.toggleClass('hidden flex justify-center items-center');
                }
                $appliedFiltersCount.find('> span').text(0);
            }

        }else if($input.attr('name') === 'realestate_type[]'){
            let selectedTypesText = null;
            const $typesInput = $('input[name="realestate_type[]"]:checked');
            // const $uncheckedTypesInput = $('input[name="realestate_type[]"]:not(:checked)');

            // in case the first-selected class is removed from the first selected type
            if($input.hasClass('first-selected')){
                $input.removeClass('first-selected');

                if($typesInput.first().length > 0){
                    $typesInput.first().addClass('first-selected');
                }
            }else if($typesInput.length === 1 && !$typesInput.hasClass('first-selected')){ //add first-selected class to the first selected type
                $input.addClass('first-selected');
            }
    
            //update the span text in real estate types filter
            selectedTypesText = $typesInput.filter('.first-selected')
                .siblings('label')
                .text()
                .trim();
            selectedTypesText = selectedTypesText?.charAt(0).toUpperCase() + selectedTypesText?.slice(1);

            //add the other selected types count to the span text
            if($typesInput.length > 1){
                selectedTypesText += ` (+${$typesInput.length - 1})`;
            }

            $span.text(selectedTypesText);

            //reset the span text if the selected types are empty and remove the first-selected class
            if($typesInput.length === 0){
                $span.text($span.attr('aria-label'));
            }

        }else{
            let $filterDropdown = null;

            if($input.attr('name') === 'ad_type'){
                $filterDropdown = $(`#ad-types-dropdown`);
            }else{
                const filterType = targetId.substring(0, targetId.indexOf('-'));
                $filterDropdown = $(`#${filterType}-dropdown`);
            }

            $span.text($input.attr('data-selectable'));
            const currentQuery = $('#search-page-filters').data('query');
            const newQuery = updateQueryString($('#search-page-filters'));
            const $openPopup = $('.search-popup-wraper.opened');
    
            $openPopup.removeClass('opened');
            if(newQuery !== currentQuery){
                $filterDropdown.data('applied', true);

                //style filter span and text when selected
                $filterDropdown.parent()
                .find('> span')
                .removeClass('bg-slate-100')
                .addClass('bg-light-blue text-blue-brand');

                //rotate back filter dropdown icon to its original position
                $filterDropdown.parent()
                .find('i')
                .css('transform', 'rotate(0deg)');

                //trigger submit to update the results on listing page
                if ($form.hasClass('listing-view')) {
                    getListingAds();
                }else if($form.hasClass('map-view')){
                    MapViewModel.realEstateAdIds = null;
                    MapViewModel.boundaries = null;
                    getMapViewAds();
                }else{
                    updateHomePageMap();
                }
            }

            //remove the overlay from the mapbox when the popup is closed
            if($mapbox.length > 0 && $mapbox.find('#mapbox-overlay').length > 0){
                $mapbox.find('#mapbox-overlay').remove();
            }
        }
    });

    // show alert modal
    $('#create-alert-btn').on('click', function () {
        $('#create-alert-modal').removeClass('hidden')
        .addClass('grid place-items-center');
    });

    // this is mirror the value of the field with [data-value] attr to the html structure so I can use tailwind to style the input if it's not empty, as I can read the input value directly in the css
    // ************** MAKE SURE TO UPDATE THIS VALUE ON REST BUTTON **************
    $('input[data-value]').each(function() {
        // Attach an event listener to handle key releases
        $(this).on('keyup', function() {
            mirrorValueToHtml(this);
        });
    });

    $('input[name="listed_by"]').on('click', function(){
        if (this === lastCheckedListedBy) {
            $(this).prop('checked', false); // Uncheck the radio button if clicked again
            lastCheckedListedBy = null; // Reset the last checked variable
        } else {
            lastCheckedListedBy = this; // Update the last checked variable to the current radio button
        }
    });
});